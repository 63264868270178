import {Tabs} from 'antd'
import React, {FunctionComponent, useMemo} from 'react'
import {ChartSource, DataSourceObjectType, Link} from 'components/dataSourceDoc/DataSource.types'
import DataSourceLayout, {ContentContainer, InformationsContainer} from "components/dataSourceDoc/DataSource.Layout"
import {CardEffectDiv} from "themes/SharedStyledComponent"
import {useNavigate, useParams} from "react-router-dom"
import {Column, MatchParams} from "components/dataSourceDoc/DataSource"
import DetailsInformations from "components/dataSourceDoc/details/Details.Informations"
import {DetailsUsages} from "components/dataSourceDoc/details/Details.Usages"
import styled from "styled-components"
import DetailsValues from "components/dataSourceDoc/details/Details.Values"
import {MetaModelView} from "@biron-data/react-bqconf"
import {Export} from "components/forms/Form.types"
import Language from "language"
import {ExpandedWorkspace} from "redux/models/workspace"
import {CardContentContainerWithLimitedHeight} from "components/dataSourceDoc/DataSource.style"
import {useDimensionUsages} from "components/dataSourceDoc/DataSource.hooks"

interface DimensionDetailsProps {
  data: Column[]
  views: MetaModelView[]
  currentWorkspace: ExpandedWorkspace
  currentExports: Export[]
}

export const DetailsDimension: FunctionComponent<DimensionDetailsProps> = ({
                                                                             data,
                                                                             views,
                                                                             currentWorkspace,
                                                                             currentExports,
                                                                           }) => {
  const navigate = useNavigate()
  const {code} = useParams<MatchParams>()
  const usages = useDimensionUsages(currentExports, currentWorkspace, code)

  const dimension = useMemo(() => {
    const matchingDimension = data.find(m => m.code === code && m.type === DataSourceObjectType.DIMENSION)
    if (matchingDimension) {
      return matchingDimension
    } else {
      navigate("/")
      return data.find(m => m.type === DataSourceObjectType.DIMENSION)!
    }
  }, [code, data, navigate])

  const links: Link[] = useMemo(() => views.filter(v => v.dimensions.find(m => m.code === code)).map(v => ({
    code: v.code,
    alias: v.alias,
  })), [code, views])

  return <DataSourceLayout code={code} alias={dimension.alias} type={DataSourceObjectType.DIMENSION}>
    <InformationsContainer>
      <DetailsInformations description={dimension.description} links={links} id={dimension.code}/>
    </InformationsContainer>
    <ContentContainer style={{maxHeight: "100%"}}>
      <StyledTabs type="card" items={[{
        key: "1",
        label: Language.get("dataDoc.details.usages"),
        children: <CardEffectDivWithoutTopLeftRadius>
          <CardContentContainerWithLimitedHeight>
            <DetailsUsages usages={usages} displaySourceColumn={true} sources={[ChartSource.SLICER, ChartSource.FILTER]}/>
          </CardContentContainerWithLimitedHeight>
        </CardEffectDivWithoutTopLeftRadius>,
      }, ...(dimension.dimension ? [{
        key: "2",
        label: Language.get("dataDoc.details.values"),
        children: <CardEffectDivWithoutTopLeftRadius>
          <CardContentContainerWithLimitedHeight>
            <DetailsValues dimensionCode={dimension.dimension.dictionaryCode}/>
          </CardContentContainerWithLimitedHeight>
        </CardEffectDivWithoutTopLeftRadius>,
      }] : [])]}>
      </StyledTabs>
    </ContentContainer>
  </DataSourceLayout>
}

const CardEffectDivWithoutTopLeftRadius = styled(CardEffectDiv)`
  border-radius: 0 var(--big-border-radius) var(--big-border-radius) var(--big-border-radius);
`

const StyledTabs = styled(Tabs)`
  height: 100%;

  .ant-tabs-content-holder {
    flex-grow: 1;
  }

  .ant-tabs-content {
    padding: 0;
    height: 100%;
  }

  .ant-tabs-tab {
    padding: 20px;
    font-size: 20px;
    border: none !important;
    background: var(--light-grey-2) !important;

    &-active {
      background: #fff !important;
    }
  }

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-nav-list {
    padding: 0;
  }
`