import React, {FunctionComponent, memo, useCallback, useMemo} from 'react'
import {useSelector} from "react-redux"
import {
  canEditDashboardContent as canEditDashboardContentSelector,
  canEditDashboardProperties as canEditDashboardPropertiesSelector
} from "redux/appEnvironment.selector"
import {getCurrentWorkspaceForBuildingUri} from "redux/workspace.selector"
import {buildWorkspaceUri} from "components/workspace/Workspace.utils"
import WorkspaceBridgeSiderContainer from "components/workspace/WorkspaceBridge.SiderContainer"
import {Route, Routes} from "react-router-dom"
import DataSourceDocDashboard from "components/dataSourceDoc/DataSource"
import AdminHome from "components/admin/AdminHome"
import DashboardBridge from "components/dashboard/Dashboard.Bridge"
import MainLayout from "components/mainLayout/MainLayout"
import useDispatch from "hooks/useDispatch"
import {NormalizedDashboardTypes} from "schemas/dashboard"
import PersonalDashboardBridge from 'components/personalDashboard/PersonalDashboard.Bridge'

// eslint-disable-next-line react/display-name
const WorkspaceBridgeContainer: FunctionComponent = memo(() => {
  const dispatch = useDispatch()
  const workspaceForBuildingUri = useSelector(getCurrentWorkspaceForBuildingUri)
  const canEditDashboardContent = useSelector(canEditDashboardContentSelector)
  const canEditDashboardProperties = useSelector(canEditDashboardPropertiesSelector)
  const workspaceUriBuilder = useCallback(
    (pathOrDashboard?: string | NormalizedDashboardTypes) => buildWorkspaceUri(workspaceForBuildingUri, pathOrDashboard),
    [workspaceForBuildingUri],
  )

  const sideContent = useMemo(() => <WorkspaceBridgeSiderContainer {...{
    workspaceUriBuilder,
  }}/>, [workspaceUriBuilder])

  return useMemo(() => <MainLayout {...{
    sideContent,
    canEditDashboardContent,
    canEditDashboardProperties,
    onEditModeChange: dispatch.appEnvironment.setDashboardEditMode,
    onDashboardSelectionChange: dispatch.currentDashboard.selectionUpdate,
  }}>
    <Routes>
      <Route path={`help/*`} element={<DataSourceDocDashboard/>}/>
      <Route path={`admin/*`} element={<AdminHome/>}/>
      <Route path={`:dashboard`} element={<DashboardBridge canEditDashboardContent={canEditDashboardContent} canEditDashboardProperties={canEditDashboardProperties}/>}/>
      <Route path={":environmentId/:uid"} element={<PersonalDashboardBridge/>}/>
    </Routes>
  </MainLayout>, [sideContent, canEditDashboardContent, canEditDashboardProperties, dispatch.appEnvironment.setDashboardEditMode, dispatch.currentDashboard.selectionUpdate])
})

export default WorkspaceBridgeContainer
