import API from 'services/api'
import {Environment} from "redux/models/environment"

export const getEnvs = (): Promise<{
  consts: {
    GOOELANALYTICS_KEY: string
    GOOELANALYTICS_KEY_4: string
    OIDC_CLIENTID: string
    OIDC_ISSUER: string
    SENTRY_URL: string
    VERSION: string
  },
  user: {
    locale?: string,
    id: number,
    email: string,
    firstName: string,
    lastName: string,
    powerUser: boolean
  }
}> => API.instanceData.get(`/env`)

export const getContext = ({environmentId, workspaceId}: { environmentId: number, workspaceId: number }) => API.instanceData.get<void, {
  environment: Environment,
  permissions: Permissions
}>(`/env/context`, {
  params: {
    environmentId,
    workspaceId,
  },
});
