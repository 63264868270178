import React, {forwardRef, useMemo} from 'react'
import {ELineChartProps} from "components/charts/line/LineChart.types"
import {computeTotalAcrossSeries, OPACITY, OPACITY_EMPHASIS} from "components/charts/Chart.constants"
import {EChartOption} from "echarts"
import EChartContainer from "components/charts/Chart.Container"
import ChartBase from "components/charts/Chart.Base"
import {asArray} from "commons/asArray"
import {standardTooltipOptions} from "components/charts/Chart.options"
import {formatValue} from "commons/format/formatter"
import {MetricDataTree} from "classes/MetricDataTree"
import Language from "language"
import {isEmpty} from "@biron-data/react-components"
import {
  applySortAndLimit,
  computeOrderByWithOption,
  tooltipMetricHeader,
  useBarChartColors,
  useColors,
} from "components/charts/Chart.utils"
import {tooltipLineFormat} from "components/charts/Chart.tooltipContent"
import {GenericEChartProps} from "components/charts/line/LineChart"
import {ChartFormat} from "@biron-data/react-bqconf"

const EPieChart = forwardRef<any, ELineChartProps>(function EPieChart(props: ELineChartProps, ref) {
  const metricIndex = 1
  const slicerIndex = 0

  const {extraConf, effectiveConf} = props.rawChartData.meta
  const {limits} = extraConf
  const orderBy = useMemo(() => effectiveConf.orderBys && effectiveConf.orderBys.length > 0 ? effectiveConf.orderBys[0] : undefined, [effectiveConf.orderBys])

  const consolidatedOrderBy = useMemo(() => computeOrderByWithOption(metricIndex, slicerIndex, orderBy, props.selection?.sortSeries), [orderBy, props.selection?.sortSeries])

  const limit = useMemo(() => limits?.length && limits?.length > 0 ? limits?.[0] : undefined, [limits])

  const parsedMetric: MetricDataTree = useMemo(() => {
    let ret: any = props.rawChartData.parsedData[0]
      .sortByValues()

    applySortAndLimit(consolidatedOrderBy?.column === 0 && props.rawChartData.meta.effectiveConf.slicers.length > 0, () => {
        if (consolidatedOrderBy?.column === metricIndex) {
          ret = ret.sortByValues(Boolean(!consolidatedOrderBy?.asc))
        }
        if (consolidatedOrderBy?.column === slicerIndex && consolidatedOrderBy?.asc) {
          ret = ret.sortByLabelsAt()
        }
      },
      () => {
        ret = ret.limitAt(false, 0, limit?.limitSeries || 100, limit?.hideOthers)
      })

    return ret
  }, [limit?.hideOthers, limit?.limitSeries, props.rawChartData.meta.effectiveConf.slicers.length, props.rawChartData.parsedData, consolidatedOrderBy?.asc, consolidatedOrderBy?.column])

  const xAxis = useMemo(() => parsedMetric.getXAxisAt(0), [parsedMetric])

  const colorBySeries = useColors(0, parsedMetric,
    props.rawChartData.meta.effectiveConf.slicers.length > 0 ? xAxis : [])

  const metricColors = useMemo(() => props.rawChartData.parsedData.map(data => data.metric.extraConf?.color), [props.rawChartData.parsedData])

  const colors = useBarChartColors(props.rawChartData.meta.effectiveConf.slicers.length, props.rawChartData.meta.effectiveConf.metrics.length, colorBySeries, xAxis, metricColors)

  const series = useMemo(() => {
    return parsedMetric.getSeries()
  }, [parsedMetric])

  const indexOfZeroValues = useMemo(() => series?.[0].values.map((value, index) => value === 0 ? index : undefined).filter(index => index !== undefined) as number[], [series])

  const radius = useMemo(() => {
    return isEmpty(props.selection.format) || props.selection.format === ChartFormat.PIE_STANDARD ? ['0%', '69%'] : ['40%', '70%']
  }, [props.selection.format])

  const options: EChartOption = useMemo(() => {
    const total = computeTotalAcrossSeries(series)
    return {
      tooltip: {
        ...standardTooltipOptions(),
        trigger: 'item',
        formatter(params) {
          const param = asArray(params)[0]
          return `
           ${tooltipMetricHeader(props.rawChartData.meta.effectiveConf)}
           ${tooltipLineFormat(parsedMetric.format, undefined, param?.marker, param?.data?.name, param?.data?.value, total)}
        `
        },
      },
      yAxis: [],
      color: colors,
      series: [{
        type: "pie",
        // center: props.dimensions.width > PIE_LEGEND_THRESHOLD ? ['40%', '50%'] : ['50%', '50%'],
        selectedMode: true,
        selectedOffset: 10,
        radius,
        emphasis: {
          itemStyle: {
            opacity: OPACITY_EMPHASIS,
          },
          focus: 'self',
        },
        minShowLabelAngle: 2,
        labelLine: {
          minTurnAngle: 90,
          showAbove: true,
        },
        label: {
          distanceToLabelLine: 4,
          bleedMargin: 1,
          edgeDistance: '100%',
          formatter: (serieInfos: { name: string, value: string | number }) => props.selection?.displayLabels ? `${serieInfos.name} \n ${formatValue(serieInfos.value, parsedMetric.format)}` : serieInfos.name,
        },
        // We expect and draw only one serie
        data: series?.[0].values.map((value, i) => indexOfZeroValues.some(index => i === index) ? undefined : ({
          value,
          name: xAxis[i],
          label: xAxis[i],
          itemStyle: {
            borderColor: 'white',
            opacity: OPACITY,
          },
          axisLine: {
            lineStyle: {
              color: "#DCE0E4",
            },
          },
        })).filter(serie => serie !== undefined),
      }],
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [series, parsedMetric, props.rawChartData.meta.effectiveConf, props.selection, xAxis, props.dimensions])

  return <ChartBase ref={ref}
                    option={options}
                    dimensions={props.dimensions}
                    yAxisFormats={[parsedMetric.format]}
                    warning={isEmpty(series?.[0].values.find(el => (el ?? 0) <= 0)) ? undefined : Language.get("configuration-warning-information-hidden")}/>
})

const PieChart = forwardRef<any, GenericEChartProps>(function PieChart(props, ref) {
  return <EChartContainer ref={ref} {...props} chart={EPieChart} rawChartData={props.chartData}/>
})

export default PieChart
