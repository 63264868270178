import DatadocBreadcrumb from "components/dataSourceDoc/utils/Breadcrumb"
import React, {memo, PropsWithChildren} from "react"
import styled from "styled-components"
import {DataSourceObjectType} from "components/dataSourceDoc/DataSource.types"

interface DataSourceDocLayoutProps extends PropsWithChildren {
  code?: string
  alias?: string
  type?: DataSourceObjectType
}

// eslint-disable-next-line react/display-name
const DataSourceLayout = memo<DataSourceDocLayoutProps>(({code, type, alias, children}) => {
  return <DocContainer>
    <Content>
      <DatadocBreadcrumb code={code} alias={alias} type={type}/>
      <StyledRow>
        {children}
      </StyledRow>
    </Content>
  </DocContainer>
})

export default DataSourceLayout

export const InformationsContainer = styled.div`
  width: 40%;
  @media (max-width: 1600px) {
    width: 100%;
    min-height: 40%;
    max-height: 40%;
  }
`

export const ContentContainer = styled.div`
  width: 60%;
  @media (max-width: 1600px) {
    width: 100%;
    flex-grow: 1;
    min-height: 0;
  }
`

const StyledRow = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 0;
  gap: 24px;
  flex-direction: row;
  overflow: hidden;

  @media (max-width: 1600px) {
    flex-direction: column;
  }
`

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 70px;
  overflow: hidden;

  @media (max-width: 1600px) {
    gap: 10px;
  }
`

const DocContainer = styled.div`
  border-left: 1px solid var(--border-color-base);
  padding: 40px;
  margin: 0;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`