import ReactGA3 from 'react-ga'
import ReactGA4 from "react-ga4"
import {WidgetTypes} from "commons/dashboard/dashboard.types"

let env: {
  keyV3: string,
  keyV4: string,
}

export const setEnv = (keyV3: string, keyV4: string) => {
  env = {
    keyV3,
    keyV4,
  }
}

export const init = (userId: string, userEmail: string) => {
  const {keyV3, keyV4, ...otherEnv} = env

  if (keyV3) {
    ReactGA3.initialize(keyV3, {
      debug: false,
      ...otherEnv,
      titleCase: false,
      gaOptions: {userId},
    })
    ReactGA3.set({dimension1: userEmail})
  }

  if (keyV4) {
    ReactGA4.initialize(keyV4, {
      ...otherEnv,
    })
    ReactGA4.gtag("set", "user_properties", {
      bironUserId: userId,
    })
  }
}

export const captureEvent = (event: {
  category: string
  action: string
  widgetType?: WidgetTypes
}) => {
  const {keyV3, keyV4} = env

  if (keyV3) {
    ReactGA3.event(event)
  }
  if (keyV4) {
    ReactGA4.event(event)
  }
}

export const pageview = (navigationUrl: string) => {
  const {keyV3, keyV4} = env

  if (keyV3) {
    ReactGA3.pageview(navigationUrl)
  }
  if (keyV4) {
    ReactGA4.send({hitType: "pageview", page: navigationUrl})
  }
}
