import React, {FunctionComponent, useMemo, useRef, useState} from 'react'
import {TableColumnProps} from 'antd'
import {buildWorkspaceUri} from "components/workspace/Workspace.utils"
import {ExpandedWorkspace, SummarizedWorkspace} from "redux/models/workspace"
import Language from "language"
import {Column} from "components/dataSourceDoc/DataSource"
import {DataSourceObjectType} from "components/dataSourceDoc/DataSource.types"
import {Search} from "components/search/Search"
import {TableLink, ColorizedTag, MdView} from "@biron-data/react-components"
import DataSourceLayout from "components/dataSourceDoc/DataSource.Layout"
import DatasourceTable from "components/dataSourceDoc/DataSource.Table"
import {
  usePercentageValueInPixels,
  useSearchOnValues,
  useTextWithHighLight,
  useTextWithHighLightAsString,
  useTypeRepresentationColor,
} from "components/dataSourceDoc/DataSource.hooks"
import {CardContentContainerWithLimitedHeight, SizeReference, TableContainer} from "components/dataSourceDoc/DataSource.style"
import {CardEffectDiv} from "themes/SharedStyledComponent"
import {useResizeDetector} from "@biron-data/react-hooks"

interface Props {
  data: Column[]
  currentWorkspace: ExpandedWorkspace | SummarizedWorkspace
}

export const DataSourceList: FunctionComponent<Props> = ({data, currentWorkspace}) => {
  const [consolidatedData, setConsolidatedData] = useState(data)
  const [search, setSearch] = useState("")

  const filteredValues = useMemo(() => [['alias'], ['description'], ['code']], [])

  useSearchOnValues(search, filteredValues, data, setConsolidatedData)

  const getTextWithHighLightAsString = useTextWithHighLightAsString(search)
  const getTextWithHighLight = useTextWithHighLight(search)

  const getTypeRepresentationColor = useTypeRepresentationColor()

  const [size, setSize] = useState({
    width: 0,
  })
  const getPercentageValueInPixels = usePercentageValueInPixels(1150, size.width)

  const columns: TableColumnProps<Column>[] = useMemo(() => {
    return size.width ? [
      {
        title: Language.get(`dataDoc.columns.object`),
        dataIndex: 'type',
        width: getPercentageValueInPixels(8),
        filters: Object.entries(DataSourceObjectType).map(([, source]) => ({
          text: Language.get("dataDoc.details.type", source),
          value: source,
        })),
        onFilter: (value, record) => record.type === value.toString(),
        render: (text: DataSourceObjectType) => <ColorizedTag
          text={Language.get("dataDoc.details.type", text)}
          color={getTypeRepresentationColor(text)}/>,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        title: Language.get(`dataDoc.columns.name`),
        dataIndex: 'alias',
        width: getPercentageValueInPixels(26),
        render: (text: string, record: Column) => {
          if (!record.view?.code) {
            return <div>{text}</div>
          }
          const path = `help/${record.type}/${record.code}`
          const link = buildWorkspaceUri(currentWorkspace, path)
          return <TableLink link={link}>{getTextWithHighLight(text)}</TableLink>
        },
      },
      {
        title: Language.get(`dataDoc.columns.description`),
        dataIndex: 'description',
        width: getPercentageValueInPixels(51),
        render: (text: string) => <MdView description={getTextWithHighLightAsString(text)} padding={0}/>,
      },
      {
        title: Language.get(`dataDoc.columns.id`),
        dataIndex: 'code',
        width: getPercentageValueInPixels(15),
        render: (text: string) => getTextWithHighLight(text),
      },
    ] : []
  }, [size.width, getPercentageValueInPixels, getTypeRepresentationColor, currentWorkspace, getTextWithHighLight, getTextWithHighLightAsString])

  const container = useRef<any>()
  useResizeDetector(container, size, (newWidth) => {
    setSize({
      width: newWidth,
    })
  })
  const onSearch = (val: string) => {
    setSearch(val)
  }
  return (<DataSourceLayout><CardEffectDiv style={{width: "100%"}}>
      <CardContentContainerWithLimitedHeight>
        <SizeReference ref={container}>
          {size.width && <TableContainer>
            <Search
              width={'calc(40% - 5px)'}
              onChange={onSearch}/>
            <DatasourceTable columns={columns} dataSource={consolidatedData} scroll={{y: 1200, x: 'max-content'}}/>
          </TableContainer>}
        </SizeReference>
      </CardContentContainerWithLimitedHeight>
    </CardEffectDiv>
    </DataSourceLayout>
  )
}

