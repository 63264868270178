/* eslint-disable max-lines */
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {useMemo} from "react"
import {pickBy} from "lodash"
import {
  ChartType,
  ChartTypeWithDisabledReason,
  ConfigCache,
  DimensionCacheElement,
  LimitType,
  MetricCacheElement,


} from "components/forms/chart/types"
import {doesFormatDiffer,
  ChartFormat,
  Format,
  GenericChartTypes,
  MetricTarget,
  SlicerTarget,
  SortType,
  TargetAffect,} from "@biron-data/react-bqconf"
import {
  getAreaDisablingReason,
  hasMetricAsRatio,
  hasMetricMultiple,
  hasMetricWithGrowthAsRatio,
  hasNotEnoughData,
  percentageBarChartDisablingReason,
  useBarChartLimits,
  useDateSlicer,
  useDefaultAxisOrderBy,
  useGetLineChartLimits,
  useOrderByMetricDesc,
  useOrderBySlicersAsc,
  usePieOrderBys,
  useScatterChartLimits,
  useTableOrderBys,
  useWithAxisDateSlicer,
  useWithoutSlicer,
  useWithSlicer,
} from "components/forms/chart/hooks"
import {isBarColorOverridable, isLineColorOverridable, replicateMetricsTarget} from "components/forms/chart/useChartTypes.utils"

export function useGenericChartConfiguration(
  slicers: Pick<DimensionCacheElement, "code" | "isDefault" | "isAxis">[],
  metrics: Pick<MetricCacheElement, "isRatio" | "growth" | "format">[]): ChartType[] {
  const dateSlicer = useDateSlicer(slicers)
  const orderBySlicersAsc = useOrderBySlicersAsc()
  const orderByMetricDesc = useOrderByMetricDesc(slicers)

  const slicersWithoutDefault = slicers?.filter(slicer => !slicer.isDefault)
  const withMetricMultiple = hasMetricMultiple(metrics)
  const withMetricUnique = Boolean(metrics && metrics?.length === 1)
  const withMetricAsRatio = hasMetricAsRatio(metrics)
  const withMetricWithGrowthAsRatio = hasMetricWithGrowthAsRatio(metrics)
  const withSlicer = useWithSlicer(slicers)
  const withoutSlicer = useWithoutSlicer(slicers)
  const withSlicerMultiple = Boolean(slicers && slicers?.length > 1)

  const withMoreThanOneSlicer = Boolean(slicers.length > 2)

  const withSlicerUniqueWithoutDefaultSlicer = Boolean(slicersWithoutDefault && slicersWithoutDefault?.length === 1)
  const withSlicerWithoutDefaultSlicer = Boolean(slicersWithoutDefault && slicersWithoutDefault?.length > 0)

  const slicersWithoutDefaultAndAxis = slicersWithoutDefault.filter(slicer => !slicer.isAxis)

  const withMoreThanOneSlicerNotAnAxis = Boolean(slicersWithoutDefaultAndAxis?.length > 1)

  const withAxisDateSlicer = useWithAxisDateSlicer(slicers)
  const withDateSlicer = Boolean(dateSlicer)

  const isFirstSlicerOfTypeDate = slicers && slicers.length > 0 && slicers[0].code === "date"

  const withMetricAndWithSlicer = !(withMetricMultiple && !withSlicerWithoutDefaultSlicer)
  const withMultipleSlicerAndMetric = !(withSlicerUniqueWithoutDefaultSlicer && withMetricUnique)

  const withMultipleMetricAndMultipleSlicers = (withMetricMultiple && slicers.length > 1) || withMoreThanOneSlicer

  const withOnlyOneSlicer = withSlicer && !withSlicerMultiple

  const withMoreThan3Metrics = metrics.length > 3

  const notEnoughData = hasNotEnoughData(metrics, slicers)

  const formats = metrics.map(m => m.format)
  const hasMultipleFormat = formats.filter((format, i) => {
      return i !== 0
        && format
        && formats[i - 1]
        && doesFormatDiffer(format, formats[i - 1] ?? {} as Format)
    },
  ).length > 0

  const defaultAxisOrderBy = useDefaultAxisOrderBy(
    withSlicer,
    withAxisDateSlicer,
    orderBySlicersAsc,
    orderByMetricDesc,
  )
  const pieSort = usePieOrderBys(
    withSlicer,
    withMetricMultiple,
    withoutSlicer,
    orderBySlicersAsc,
    orderByMetricDesc,
  )
  const tableSort = useTableOrderBys(
    orderBySlicersAsc,
  )

  const getLineChartLimit = useGetLineChartLimits(
    isFirstSlicerOfTypeDate,
    withSlicerMultiple,
    withOnlyOneSlicer,
  )

  const barChartLimits = useBarChartLimits(
    isFirstSlicerOfTypeDate,
    withSlicer,
    withSlicerMultiple,
    withOnlyOneSlicer,
  )

  const scatterChartLimits = useScatterChartLimits(
    withoutSlicer,
    withSlicerMultiple,
  )
  return useMemo(() => [
    {
      type: GenericChartTypes.BOXES,
      nextCompatibleTypes: [GenericChartTypes.TABLES],
      format: [],
      enableDateSlicer: false,
      metricTarget: replicateMetricsTarget(metrics, MetricTarget.NUMBER),
      slicerTargets: [],
      isMetricsFilterEnabled: false,
      isColorOverridable: false,
      disablingReasons: {
        withSlicerWithoutDefaultSlicer,
      },
    },
    {
      type: GenericChartTypes.BARS,
      nextCompatibleTypes: [GenericChartTypes.TABLES],
      enableDateSlicer: true,
      metricTarget: replicateMetricsTarget(metrics, MetricTarget.BAR),
      isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
      slicerTargets: slicers.map((_, index) => index === 0 ? SlicerTarget.AXE : SlicerTarget.COLOR),
      orderBys: [
        defaultAxisOrderBy,
      ],
      limits: barChartLimits,
      format: [
        {
          type: ChartFormat.V_GROUPED,
          isMetricsFilterEnabled: false,
          isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
          disablingReasons: {},
        },
        {
          type: ChartFormat.V_STACKED,
          isMetricsFilterEnabled: false,
          isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
          disablingReasons: {
            hasMultipleFormat,
            notEnoughData,
          },
        },
        {
          type: ChartFormat.PERCENTAGE_V_STACKED,
          isMetricsFilterEnabled: false,
          isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
          disablingReasons: percentageBarChartDisablingReason(metrics, slicers),
        },
        {
          type: ChartFormat.H_GROUPED,
          isMetricsFilterEnabled: false,
          isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
          disablingReasons: {},
        },
        {
          type: ChartFormat.H_STACKED,
          isMetricsFilterEnabled: false,
          isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
          disablingReasons: {
            hasMultipleFormat,
            notEnoughData,
          },
        },
        {
          type: ChartFormat.PERCENTAGE_H_STACKED,
          isMetricsFilterEnabled: false,
          isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
          disablingReasons: percentageBarChartDisablingReason(metrics, slicers),
        },
      ],
      isMetricsFilterEnabled: withOnlyOneSlicer && !withDateSlicer,
      disablingReasons: {
        withMultipleMetricAndMultipleSlicers,
      }
    },
    {
      type: GenericChartTypes.SCATTER,
      nextCompatibleTypes: [GenericChartTypes.TABLES],
      enableDateSlicer: true,
      metricTarget: [MetricTarget.XAXIS, MetricTarget.YAXIS, MetricTarget.SIZE],
      isColorOverridable: false,
      slicerTargets: slicers.map((_, index) => index === 0 ? SlicerTarget.POINTS : SlicerTarget.COLOR),
      orderBys: [],
      limits: scatterChartLimits,
      format: [],
      isMetricsFilterEnabled: true,
      disablingReasons: {
        withMoreThanOneSlicer,
        withMoreThan3Metrics,
      },
    },
    {
      type: GenericChartTypes.TABLES,
      nextCompatibleTypes: [],
      enableDateSlicer: true,
      isColorOverridable: false,
      metricTarget: replicateMetricsTarget(metrics, MetricTarget.COLUMN),
      slicerTargets: [SlicerTarget.COLUMN],
      format: [],
      orderBys: [tableSort],
      limits: [
        {
          type: LimitType.SIMPLE,
          affect: TargetAffect.TABLE,
          mandatory: false,
          default: {
            value: undefined,
            enabled: true,
          },
          disablingReasons: {},
          displaySlicerOtherOption: false,
        },
      ],
      isMetricsFilterEnabled: true,
      disablingReasons: {},
    },
    {
      type: GenericChartTypes.PIE,
      nextCompatibleTypes: [GenericChartTypes.BARS],
      enableDateSlicer: true,
      isColorOverridable: withoutSlicer,
      metricTarget: [MetricTarget.PART],
      slicerTargets: [SlicerTarget.COLOR],
      format: [
        {
          type: ChartFormat.PIE_STANDARD,
          isMetricsFilterEnabled: false,
          isColorOverridable: withoutSlicer,
          disablingReasons: {},
        },
        {
          type: ChartFormat.DONUT,
          isMetricsFilterEnabled: false,
          isColorOverridable: withoutSlicer,
          disablingReasons: {},
        },
      ],
      orderBys: pieSort,
      limits: [{
        type: LimitType.SIMPLE,
        mandatory: withSlicer,
        default: withSlicer ? {
          enabled: true,
          value: 20,
        } : undefined,
        disablingReasons: {},
        displaySlicerOtherOption: withSlicer,
        affect: TargetAffect.PART,
      }],
      isMetricsFilterEnabled: false,
      disablingReasons: {
        withMetricAsRatio,
        withMetricWithGrowthAsRatio,
        withMetricMultiple,
        withMoreThanOneSlicerNotAnAxis,
      },
    },
    {
      type: GenericChartTypes.LINE,
      nextCompatibleTypes: [GenericChartTypes.BARS, GenericChartTypes.TABLES],
      enableDateSlicer: true,
      isColorOverridable: isLineColorOverridable(slicers.length),
      metricTarget: replicateMetricsTarget(metrics, MetricTarget.LINE),
      slicerTargets: slicers.map((_, index) => index === 0 ? SlicerTarget.AXE : SlicerTarget.COLOR),
      defaultSlicer: {
        value: {
          type: "date",
          granularity: undefined,
        },
        disablingReasons: {
          withMetricAndWithSlicer,
          withMultipleSlicerAndMetric,
        },
      },
      orderBys: [
        defaultAxisOrderBy,
      ],
      limits: getLineChartLimit(TargetAffect.LINE),
      format: [{
        type: ChartFormat.LINE_STANDARD,
        isMetricsFilterEnabled: false,
        isColorOverridable: isLineColorOverridable(slicers.length),
        disablingReasons: {},
      }],
      disablingReasons: {
        withMultipleMetricAndMultipleSlicers,
      },
      isMetricsFilterEnabled: false,
    },
    {
      type: GenericChartTypes.AREA,
      nextCompatibleTypes: [GenericChartTypes.BARS, GenericChartTypes.TABLES],
      enableDateSlicer: true,
      isColorOverridable: isLineColorOverridable(slicers.length),
      metricTarget: replicateMetricsTarget(metrics, MetricTarget.AREA),
      slicerTargets: slicers.map((_, index) => index === 0 ? SlicerTarget.AXE : SlicerTarget.COLOR),
      defaultSlicer: {
        value: {
          type: "date",
          granularity: undefined,
        },
        disablingReasons: {
          withMetricAndWithSlicer,
          withMultipleSlicerAndMetric,
        },
      },
      orderBys: [
        defaultAxisOrderBy,
      ],
      limits: getLineChartLimit(TargetAffect.AREA),
      format: [
        {
          type: ChartFormat.AREA_STANDARD,
          isMetricsFilterEnabled: false,
          isColorOverridable: isLineColorOverridable(slicers.length),
          disablingReasons: {},
        },
        {
          type: ChartFormat.AREA_PERCENTAGE,
          isMetricsFilterEnabled: false,
          isColorOverridable: isLineColorOverridable(slicers.length),
          disablingReasons: {},
        },
      ],
      isMetricsFilterEnabled: false,
      disablingReasons: getAreaDisablingReason(metrics, slicers),
    } as ChartType,
  ], [metrics, slicers, defaultAxisOrderBy, barChartLimits, hasMultipleFormat, notEnoughData, withOnlyOneSlicer, withDateSlicer, withMultipleMetricAndMultipleSlicers, scatterChartLimits, withMoreThanOneSlicer, withMoreThan3Metrics, withSlicerWithoutDefaultSlicer, tableSort, withoutSlicer, pieSort, withSlicer, withMetricAsRatio, withMetricWithGrowthAsRatio, withMetricMultiple, withMoreThanOneSlicerNotAnAxis, withMetricAndWithSlicer, withMultipleSlicerAndMetric, getLineChartLimit])
}

const useTargetConfiguration = (): ChartType[] => useMemo(() => [{
  type: WidgetTypes.TARGET,
  nextCompatibleTypes: [],
  format: [],
  metricTarget: [MetricTarget.TARGET],
  slicerTargets: [],
  disablingReasons: {},
  isMetricsFilterEnabled: false,
  isColorOverridable: false,
} as ChartType], [])

const useExportConfiguration = (metrics: MetricCacheElement[]): ChartType[] => {
  const SORT_SLICER_ALPHABETICAL = useMemo(() => ({
    column: 0,
    asc: true,
  }), [])

  return useMemo(() => [{
    type: WidgetTypes.EXPORT,
    nextCompatibleTypes: [],
    enableDateSlicer: true,
    isColorOverridable: false,
    metricTarget: replicateMetricsTarget(metrics, MetricTarget.COLUMN),
    slicerTargets: [SlicerTarget.COLUMN],
    format: [],
    orderBys: [{
      default: SORT_SLICER_ALPHABETICAL,
      editable: true,
      affect: TargetAffect.TABLE,
      type: SortType.ARRAY,
      disablingReasons: {},
    }],
    limits: [],
    isMetricsFilterEnabled: true,
    disablingReasons: {},
  } as ChartType], [SORT_SLICER_ALPHABETICAL, metrics])
}

const useAppropriateConfiguration = (type: WidgetTypes, slicers: DimensionCacheElement[], metrics: MetricCacheElement[]) => {
  const genericConfiguration = useGenericChartConfiguration(slicers, metrics)

  const targetConfiguration = useTargetConfiguration()

  const exportConfiguration = useExportConfiguration(metrics)

  switch (type) {
    case WidgetTypes.GENERIC:
      return genericConfiguration
    case WidgetTypes.TARGET:
      return targetConfiguration
    case WidgetTypes.EXPORT:
      return exportConfiguration
    default:
      return []
  }
}

export function useChartTypes(
  type: WidgetTypes,
  configCache?: ConfigCache): ChartTypeWithDisabledReason[] {
  const slicers = useMemo(() => configCache?.slicers ?? [], [configCache])
  const metrics = useMemo(() => configCache?.metrics ?? [], [configCache])

  const configuration = useAppropriateConfiguration(type, slicers, metrics)

  return useMemo(() => configuration.map(test => ({
    ...test,
    ...test,
    disabledReasons: Object.keys(pickBy(test.disablingReasons, Boolean)),
    format: test.format.map(format => ({
        ...format,
        disabledReasons: Object.keys(pickBy(format.disablingReasons, Boolean)),
      })),
  })), [configuration])
}