import React, {memo, useCallback, useMemo, useRef} from 'react'
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import MainLayoutSider from "components/mainLayout/MainLayout.Sider"
import {useSelector} from "react-redux"
import {getCurrentMetaModel} from "redux/environment.selector"
import {getPermission, getUser} from "redux/appEnvironment.selector"
import {getAvailables, getCurrentWorkspaceExpanded} from "redux/workspace.selector"
import {useNavigate} from "react-router-dom"
import {MetaModel, ColorName, GenericChartTypes} from "@biron-data/react-bqconf"
import {ExpandedWorkspace} from "redux/models/workspace"
import useDispatch from "hooks/useDispatch"
import {FormItemKeys} from "commons/keywords/form"
import {ChartExtraConf} from "types/widgets"
import {FormKeys} from "components/forms/Form.types"
import {NormalizedDashboardTypes} from "schemas/dashboard"
import {DashboardDtoTypes, DashboardTypes} from "types/dashboards"
import {PermissionsEnum} from "redux/models/appEnvironment"

interface WorkspaceSiderProps {
  workspaceUriBuilder: (pathOrDashboard?: string | NormalizedDashboardTypes) => string
}

export interface BaseEditionInformation {
  id?: number
  title?: string
  name?: string
  cssClass?: ColorName
  extraConf?: ChartExtraConf
}

export interface DividerConf extends BaseEditionInformation {
  type: WidgetTypes | GenericChartTypes,
}

export interface MenuDto extends BaseEditionInformation {
  [FormItemKeys.POSITION_MENU]: string | number
}

export interface DashboardDto extends BaseEditionInformation {
  parentMenu: number,
  positionDashboard: number
}

export type HandleMenuActionParamTypes = {
  eventKey: FormKeys.ADD_MENU,
  value: MenuDto,
  specificId: undefined
} | {
  eventKey: FormKeys.EDIT_MENU,
  value: MenuDto,
  specificId: number
} | {
  eventKey: FormKeys.ADD_DASHBOARD,
  value: DashboardDto,
  specificId: number
} | {
  eventKey: FormKeys.EDIT_DASHBOARD,
  value: DashboardDto,
  specificId: number
} | {
  eventKey: FormKeys.CLONE_DASHBOARD,
  value: DashboardDto,
  specificId: number
}

export type HandleMenuActionParamValueTypes =
  MenuDto
  | DashboardDtoTypes
  | DashboardDtoTypes & { dashboardId: number, parentMenu: number, positionDashboard: number }
  | { dashboardId: number, value: any }
  | { dashboardId: number, menuId: number }

const WorkspaceBridgeSiderContainer = memo<WorkspaceSiderProps>(function WorkspaceSiderContainer({workspaceUriBuilder}) {
  const dispatch = useDispatch()
  const metaModel = useSelector(getCurrentMetaModel) as MetaModel
  const workspaces = useSelector(getAvailables)
  const workspace = useSelector(getCurrentWorkspaceExpanded) as ExpandedWorkspace
  const getPermissionSelector = useSelector(getPermission)
  const userInfos = useSelector(getUser)
  const onLogout = dispatch.appEnvironment.logout
  const navigate = useRef(useNavigate())

  const navigation = useMemo(() => generateNavigation({
      workspace,
      workspaceUriBuilder,
      getPermissionSelector,
      workspaces,
    }),
    [workspace, workspaceUriBuilder, getPermissionSelector, workspaces])

  const handleMenuAction = useCallback(async ({eventKey, value, specificId}: HandleMenuActionParamTypes) => {
    if (eventKey === FormKeys.ADD_MENU) {
      await dispatch.workspace.addMenu(value)
    } else if (eventKey === FormKeys.EDIT_MENU) {
      await dispatch.workspace.editMenu({
        value,
        menuId: specificId,
      })
    } else if (eventKey === FormKeys.ADD_DASHBOARD) {
      const newDashboard = await dispatch.workspace.addDashboard(value)
      if (newDashboard.type === DashboardTypes.workspace) {
        navigate.current(workspaceUriBuilder(newDashboard.uri))
      }
    } else if (eventKey === FormKeys.EDIT_DASHBOARD) {
      await dispatch.workspace.editDashboard({...value, dashboardId: specificId})
    } else if (eventKey === FormKeys.CLONE_DASHBOARD) {
      const clonedDashboard = await dispatch.workspace.cloneDashboard({
        dashboardId: specificId,
        value,
      })
      if (clonedDashboard.type === DashboardTypes.workspace) {
        navigate.current(workspaceUriBuilder(clonedDashboard.uri))
      }
    } else {
      throw new Error(`unsupported action [${eventKey}]`)
    }
  }, [dispatch.workspace, workspaceUriBuilder])

  return useMemo(() => <MainLayoutSider {...{
    ...navigation,
    ...userInfos,
    getPermission: getPermissionSelector,
    workspaceUriBuilder,
    onMenuAction: handleMenuAction,
    metaModel,
    workspace,
    onLogout,
  }} />, [getPermissionSelector, handleMenuAction, metaModel, navigation, onLogout, userInfos, workspace, workspaceUriBuilder])
})

export default WorkspaceBridgeSiderContainer

interface GenerateNavigationProps {
  workspace: any
  workspaceUriBuilder: any
  getPermissionSelector: (perm: PermissionsEnum) => boolean
  workspaces: any
}

const generateNavigation = ({
                              workspace,
                              workspaceUriBuilder,
                              getPermissionSelector,
                              workspaces,
                            }: GenerateNavigationProps): { currentWorkspace: any, workspaces: any, items: any[], canAddMenu: boolean } => {
  const permWorkspaceCanEdit = getPermissionSelector(PermissionsEnum.workspaceCanEdit)
  const buildLink = workspaceUriBuilder
  return {
    currentWorkspace: workspace,
    workspaces,
    items: [
      {
        key: 'home',
        label: workspace.homepage.title,
        link: buildLink(workspace.homepage),
        type: 'link',
      },
      // @ts-ignore
      ...workspace.menus.map((menu, menuIndex) => ({
        key: `menu:${menu.id}`,
        label: menu.name,
        type: 'menu',
        extraActions: permWorkspaceCanEdit && [
          {
            key: FormKeys.EDIT_MENU,
            data: {
              menu,
              menuIndex,
            },
          },
          {
            key: FormKeys.ADD_DASHBOARD,
            data: {
              menu,
              menuIndex,
            },
          },
        ],
        children: menu.dashboards.map((dashboard: DashboardDtoTypes, dashboardIndex: number) => ({
          label: dashboard.title,
          key: `dashboard:${dashboard.id}`,
          link: buildLink(dashboard),
          type: 'link',
          extraActions: permWorkspaceCanEdit && [
            {
              key: FormKeys.EDIT_DASHBOARD,
              data: {
                menu,
                dashboard,
                dashboardIndex,
              },
            },
            {
              key: FormKeys.CLONE_DASHBOARD,
              data: {
                menu,
                dashboard,
                dashboardIndex,
              },
            },
          ],
        })),
      })),
    ],
    canAddMenu: permWorkspaceCanEdit,
  }
}
