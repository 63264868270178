import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import FormModal from "components/forms/Form.Modal"
import FormComponentTarget from "components/forms/chart/FormComponentTarget"
import {useRequirements, MetaModel, DataSelection, LoadDictionaryEntriesWithoutEnvironment} from "@biron-data/react-bqconf"
import {formDataToChartTarget, uniqueViewOptions} from "components/forms/chart/utils"
import {ChartTargetDtoDetail} from "types/charts"
import {getTargets} from "services/TargetService"
import Target from "components/charts/target/TargetChart"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {useChartTypes} from "components/forms/chart/useChartTypes"
import Language from "language"
import {useGetPopupContainer} from "@biron-data/react-hooks"
import {SimplifiedChartTargetFormProps} from "components/forms/chart/types"
import {useAdditionalDetails, useHandlePartialFormDataChange} from "components/forms/chart/hooks"

export interface TargetExtendedConfModel extends ChartTargetDtoDetail {
  displayType: WidgetTypes
  uniqueView: string | null
  targets: Target[]
  metrics: {
    metricCode: string
    viewCode: string
  }[]
  printPrevisions?: boolean
  ignoreSeasonality?: boolean
}

interface Props {
  data: ChartTargetDtoDetail
  environmentId: number
  metaModel: MetaModel
  loadDictionaryEntries: LoadDictionaryEntriesWithoutEnvironment,
  onConfirm: (data: TargetExtendedConfModel, requiredViewsCode: string[]) => void
  onCancel?: () => void
  dashboardSelection: DataSelection
}


const formatTargetChartMetric = (formData: Omit<TargetExtendedConfModel, 'viewCode' | 'metricCode'>) => ({
  metricCode: formData.metrics.length > 0 ? formData.metrics[0].metricCode : undefined,
  viewCode: formData.metrics.length > 0 ? formData.metrics[0].viewCode : undefined,
})

const formatTargetMetrics = (data: ChartTargetDtoDetail) => ({
  metrics: data.viewCode && data.metricCode ? [
    {
      viewCode: data.viewCode,
      metricCode: data.metricCode,
    },
  ] : [],
})

const FormChartTargetCmp: (props: Props) => JSX.Element | null = ({
                                                                    data: originalData,
                                                                    environmentId,
                                                                    metaModel,
                                                                    loadDictionaryEntries,
                                                                    onConfirm,
                                                                    onCancel,
                                                                    dashboardSelection,
                                                                  }) => {
  const popupContainerRef = useRef(null)
  const initialData = useMemo<TargetExtendedConfModel>(
    () => ({
      ...originalData,
      ...formatTargetMetrics(originalData),
      uniqueView: metaModel.getView((originalData as ChartTargetDtoDetail).viewCode)?.code ?? null,
      displayType: WidgetTypes.TARGET,
      targets: [],
      printPrevisions: originalData.extraConf.printPrevisions,
      ignoreSeasonality: originalData.extraConf.ignoreSeasonality,
    }),
    [originalData, metaModel],
  )

  const [formData, setData] = useState<TargetExtendedConfModel>({
    ...initialData,
    ...initialData.extraConf,
    displayType: WidgetTypes.TARGET,
    targets: [],
  })

  const getPopupContainer = useGetPopupContainer(popupContainerRef.current)

  const updateTargets = useCallback(() => {
    if (formData.metrics && formData.metrics.length >= 0 && formData.metrics[0]) {
      getTargets({
          metricCode: formData.metrics[0].metricCode,
          viewCode: formData.metrics[0].viewCode,
        environmentId,
        })
        .then((result) => {
          setData((oldData) => ({
            ...oldData,
            targets: result as unknown as Target[],
          }))
        })
    }
    {
      setData((oldData) => ({
        ...oldData,
        targets: [],
      }))
    }
  }, [environmentId, formData.metrics])

  useEffect(() => {
    updateTargets()
  }, [environmentId, formData.metrics, updateTargets])

  const {
    availableViews: viewsWithMetrics,
    unavailableViews,
    requiredViewsCode,
  } = useRequirements(metaModel, formData.uniqueView)

  const chartTypes = useChartTypes(formData.type)

  const additionalDetails = useAdditionalDetails(formData.type)


  const formProps = useMemo<SimplifiedChartTargetFormProps>(() => ({
      uniqueViewOptions: uniqueViewOptions(metaModel),
      metricInvertible: false,
      loadDictionaryEntries,
      metaModel,
      environmentId,
      chartTypes,
      viewsWithMetrics,
      unavailableViews,
      requiredViewsCode,
      additionalDetails,
      getPopupContainer,
      dashboardSelection,
    }),
    [metaModel, loadDictionaryEntries, environmentId, chartTypes, viewsWithMetrics, unavailableViews, requiredViewsCode, additionalDetails, getPopupContainer, dashboardSelection],
  )

  const handleConfirm = useCallback(
    (newData: Omit<TargetExtendedConfModel, 'viewCode' | 'metricCode'>) => {
      const targetChartMetric = formatTargetChartMetric(newData)
      if (targetChartMetric.metricCode && targetChartMetric.viewCode) {
        onConfirm({
          ...newData,
          ...formDataToChartTarget(newData, Language.get(`new-chart-title`)),
          ...formatTargetChartMetric(newData),
          metricCode: targetChartMetric.metricCode,
          viewCode: targetChartMetric.viewCode,
        }, requiredViewsCode)
      }
    }, [onConfirm, requiredViewsCode],
  )

  const handlePartialFormDataChange = useHandlePartialFormDataChange(setData)

  return <FormModal<TargetExtendedConfModel, SimplifiedChartTargetFormProps> {...{
    ref: popupContainerRef,
    defaultTitle: Language.get(`new-chart-title`),
    isTitleEditable: true,
    width: 1500,
    theme: {},
    data: formData,
    setData: handlePartialFormDataChange,
    renderFormComponent: (props) => <FormComponentTarget {...props}/>,
    onConfirm: handleConfirm,
    onCancel,
    formProps,
  }} />
}

export default FormChartTargetCmp

