import * as Sentry from '@sentry/browser'
import {forEach} from "lodash"

let dsn: string | undefined
export const init = (url: string) => {
  if (!url) {
    // eslint-disable-next-line no-console
    console.warn("Could not retrieve Sentry DSN")
  }
  dsn = url
  if (dsn && (!import.meta.env.VITE_ENVIRONMENT || import.meta.env.VITE_ENVIRONMENT !== 'development')) {
    Sentry.init({
      dsn,
      environment: 'production', // always production, devel/test are using a different project
      release: import.meta.env.VITE_VERSION, // eslint-disable-line
    })
  }
}

export const configureScope = (username: string) => {
  const user = {username}
  Sentry.configureScope(scope => scope.setUser(user))
}

export const captureError = (errorMessage: string, tags: Record<string, any> = {}, cause?: Error | string) => {
  const error = new Error(errorMessage, {cause: (typeof cause === 'string') ? new Error(cause) : cause})
  // eslint-disable-next-line no-undef
  if (dsn && (!import.meta.env.VITE_ENVIRONMENT || import.meta.env.VITE_ENVIRONMENT !== 'development')) {
    Sentry.withScope(scope => {
      scope.setLevel("error")
      forEach(tags, (tagValue, tagName) => {
        scope.setTag(tagName, tagValue)
      })
      Sentry.captureException(error)
    })
  } else {
    // eslint-disable-next-line no-console
    console.warn('sentry is not configured although an error was thrown', error, tags)
  }
}

export const captureWarn = (message: string) => {
  // eslint-disable-next-line no-undef
  if (dsn && (!import.meta.env.VITE_ENVIRONMENT || import.meta.env.VITE_ENVIRONMENT !== 'development')) {
    // eslint-disable-next-line no-console
    console.warn(message)
    Sentry.captureMessage(message)
  }
}
