import React, {useCallback, useMemo} from "react"
import {DashboardTypes, Folder, PersonalDashboardDtoWithoutChart, RawPersonalDashboardDto} from "types/dashboards"
import {Button, notification, Switch} from "antd"
import Language from "language"
import {IconContainer, TooltipPopover} from "@biron-data/react-components"
import {BookmarkIcon as SolidBookmarkIcon} from "@heroicons/react/solid"
import {ArrowCircleUpIcon, BookmarkIcon as OutlineBookmarkIcon, DocumentAddIcon} from "@heroicons/react/outline"
import FormTriggerable from "components/forms/Form.Triggerable"
import {descriptionTypePopover} from "components/forms/FormTrigger"
import {FormKeys} from "components/forms/Form.types"
import {DashboardDto} from "components/workspace/WorkspaceBridge.SiderContainer"
import {buildWorkspaceUri} from "components/workspace/Workspace.utils"
import {FormItemKeys} from "commons/keywords/form"
import {EditMode, FlexDiv, SwitchState} from "./MainLayout.Header.style"
import {ExpandedWorkspace} from "redux/models/workspace"
import {
  NormalizedDashboardTypes,
  NormalizedPersonalDashboard,
  NormalizedPersonalDashboardTypes,
  NormalizedWorkspaceDashboard,
} from "schemas/dashboard"
import {NavigateFunction} from "react-router-dom"
import {useMediaDesktop} from "@biron-data/react-hooks"
import {CurrentDashboardTypes} from "redux/models/currentDashboard.types"
import {omit} from "lodash"
import {Restore} from "@biron-data/icons/dist/outlined"

export const useGetEditButton = (editMode: boolean, onEditModeChange: ((newState: boolean) => void) | undefined) => {
  const isMediaDesktop = useMediaDesktop()
  return useCallback(() => <EditMode $editmode={editMode ? 1 : 0} $enabled={isMediaDesktop ? 1 : 0} onClick={() => {
    onEditModeChange?.(!editMode)
  }}>
    <Switch {...{
      checked: editMode,
    }}/>
    <SwitchState>
      {Language.get('dashboard-edit-mode')}
    </SwitchState>
  </EditMode>, [editMode, isMediaDesktop, onEditModeChange])
}

export const useGetPinButton = (
  confUpdate: (payload: (Omit<NormalizedWorkspaceDashboard, "charts"> | Omit<NormalizedPersonalDashboard, "charts">)) => Promise<void>,
) => useCallback((
  dashboard: Omit<NormalizedWorkspaceDashboard, "charts"> | Omit<NormalizedPersonalDashboard, "charts">,
) => {
  if (dashboard.type !== DashboardTypes.personal) {
    return <></>
  }
  const isUnstarAction = dashboard!.folder === Folder.STARRED
  const languageKey = isUnstarAction ? 'unpin' : 'pin'
  return <TooltipPopover size={'small'} mouseEnterDelay={0.4} content={Language.get(`personal-dashboard.tooltip.${languageKey}`)}>
    <Button
      type={dashboard.folder === Folder.STARRED ? "primary" : "default"}
      onClick={() => {
        confUpdate({
          ...dashboard!,
          folder: dashboard!.folder === Folder.STARRED ? Folder.DRAFT : Folder.STARRED,
        })
        notification.info({
          duration: 2.5,
          key: `${languageKey}-draft`,
          message: Language.get(`personal-dashboard.popup.${languageKey}-draft`, dashboard!.title),
          placement: 'bottomRight',
        })
      }}><FlexDiv>
      <IconContainer>
        {
          dashboard.type === DashboardTypes.personal
          && dashboard.folder === Folder.STARRED ? <SolidBookmarkIcon/> : <OutlineBookmarkIcon/>
        }
      </IconContainer>{Language.get(`personal-dashboard.header.button.${languageKey}`)}</FlexDiv></Button>
  </TooltipPopover>
}, [confUpdate])

export const useGetImportButton = (
  currentEnvironmentId: number,
  navigate: NavigateFunction,
  cloneDashboard: (payload: {dashboardId: number}) => Promise<RawPersonalDashboardDto>,
) => useCallback((
  dashboard: Omit<NormalizedWorkspaceDashboard, "charts"> | Omit<NormalizedPersonalDashboard, "charts">,
  ) => <TooltipPopover size={'small'} mouseEnterDelay={0.4} content={Language.get('personal-dashboard.tooltip.import')}>
  <Button
    type={"primary"}
    onClick={async () => {
      if (dashboard && dashboard!.type === DashboardTypes.personal) {
        const newDashboard = await cloneDashboard({dashboardId: dashboard.id})
        notification.info({
          duration: 2.5,
          key: `import`,
          message: Language.get(`personal-dashboard.popup.import`, dashboard!.title),
          placement: 'bottomRight',
        })
        navigate(`/app/personal/${currentEnvironmentId}/${newDashboard.uid}`)
      }
    }}><FlexDiv>
    <IconContainer>
      <DocumentAddIcon/>
    </IconContainer>{Language.get('personal-dashboard.header.button.import')}</FlexDiv></Button>
</TooltipPopover>, [cloneDashboard, currentEnvironmentId, navigate])

export const useGetRestoreButton = (
  confUpdate: (payload: (Omit<NormalizedWorkspaceDashboard, "charts"> | Omit<NormalizedPersonalDashboard, "charts">)) => Promise<void>,
) => useCallback((
  dashboard: Omit<NormalizedWorkspaceDashboard, "charts"> | Omit<NormalizedPersonalDashboard, "charts">,
  ) => <TooltipPopover size={'small'} mouseEnterDelay={0.4} content={Language.get('personal-dashboard.tooltip.restore')}>
  <Button
    type={"primary"}
    onClick={() => {
      if (dashboard!.type === DashboardTypes.personal) {
        confUpdate({
          ...dashboard!,
          folder: Folder.DRAFT,
        })
        notification.info({
          duration: 2.5,
          key: `restored-draft`,
          message: Language.get(`personal-dashboard.popup.restored-draft`, dashboard!.title),
          placement: 'bottomRight',
        })
      }
    }}><FlexDiv>
    <IconContainer>
      <Restore/>
    </IconContainer>{Language.get('personal-dashboard.popup.restore')}</FlexDiv></Button>
</TooltipPopover>, [confUpdate])

export const useGetPublishButton = (
  cloneAsWorkspaceDashboard: ((payload: {
    dashboardId: number
    value: DashboardDto
  }) => Promise<NormalizedDashboardTypes>) & {isEffect: true},
  updatePersonalDashboardFolder: ({dashboard, newFolderState, redirect}: {
    dashboard: Pick<NormalizedPersonalDashboardTypes, "uid" | "id">,
    newFolderState: Folder,
    redirect?: (removedUid: string) => void
  }) => Promise<void>,
  navigate: NavigateFunction,
) => useCallback((
  dashboard: Omit<NormalizedWorkspaceDashboard, "charts"> | Omit<NormalizedPersonalDashboard, "charts">,
  currentWorkspace: ExpandedWorkspace,
) => <FormTriggerable {...{
    descriptionType: descriptionTypePopover,
    trigger: <Button type={"primary"}><FlexDiv><IconContainer><ArrowCircleUpIcon/></IconContainer>{Language.get('personal-dashboard.publish')}</FlexDiv></Button>,
    formType: {type: FormKeys.CLONE_DASHBOARD},
    dashboardId: dashboard.id,
    workspace: currentWorkspace,
    onChange: async (value: DashboardDto) => {
      if (dashboard) {
        const clonedDashboard = await cloneAsWorkspaceDashboard({
          dashboardId: dashboard.id,
          value
        })
        if (clonedDashboard.type === DashboardTypes.workspace && currentWorkspace) {
          navigate(buildWorkspaceUri(currentWorkspace, clonedDashboard.uri))
          updatePersonalDashboardFolder({
            dashboard: {
              id: dashboard.id,
              uid: (dashboard as PersonalDashboardDtoWithoutChart).uid,
            },
            newFolderState: Folder.TRASH,
          })
        }
      }
    },
    value: {
      id: dashboard.id,
      [FormItemKeys.PARENT_MENU]: 1,
      [FormItemKeys.TITLE]: dashboard.title,
      [FormItemKeys.POSITION_DASHBOARD]: 1,
    },
    name: Language.get('form-title-copy-of-xxx', {ref: dashboard.title}),
    title: Language.get('personal-dashboard.header.button.publish'),
  }}/>, [cloneAsWorkspaceDashboard, navigate, updatePersonalDashboardFolder])

export const useAction = (
  currentDashboard: CurrentDashboardTypes,
  currentEnvironmentId: number,
  currentWorkspace: ExpandedWorkspace,
  canEditDashboardContent: boolean,
  canEditDashboardProperties: boolean,
  editMode: boolean,
  onEditModeChange: ((newState: boolean) => void) | undefined,
  confUpdate: (payload: (Omit<NormalizedWorkspaceDashboard, "charts"> | Omit<NormalizedPersonalDashboard, "charts">)) => Promise<void>,
  cloneAsPersonalDashboard: (payload: {dashboardId: number}) => Promise<RawPersonalDashboardDto>,
  cloneAsWorkspaceDashboard: ((payload: {
    dashboardId: number
    value: DashboardDto
  }) => Promise<NormalizedDashboardTypes>) & {isEffect: true},
  updatePersonalDashboardFolder: ({dashboard, newFolderState, redirect}: {
    dashboard: Pick<NormalizedPersonalDashboardTypes, "uid" | "id">,
    newFolderState: Folder,
    redirect?: (removedUid: string) => void
  }) => Promise<void>,
  navigate: NavigateFunction,
) => {
  const getEditButton = useGetEditButton(editMode, onEditModeChange)
  const getPinButton = useGetPinButton(confUpdate)
  const getImportButton = useGetImportButton(currentEnvironmentId, navigate, cloneAsPersonalDashboard)
  const getRestoreButton = useGetRestoreButton(confUpdate)
  const getPublishButton = useGetPublishButton(cloneAsWorkspaceDashboard, updatePersonalDashboardFolder, navigate)

  return useMemo(() => {
    switch (currentDashboard.type) {
      case DashboardTypes.workspace:
        if (canEditDashboardContent || canEditDashboardProperties) {
          return getEditButton()
        }
        return <></>
      case DashboardTypes.personal: {
        if (!currentDashboard.dashboard) {
          return <></>
        }
        const dashboardWithoutCharts = omit(currentDashboard.dashboard, "charts")
        const pinButton = getPinButton(dashboardWithoutCharts)
        const importButton = getImportButton(dashboardWithoutCharts)
        const restoreButton = getRestoreButton(dashboardWithoutCharts)
        return <FlexDiv>
          {currentDashboard.ownedByUser ? (() => {
            switch(currentDashboard.dashboard?.folder) {
              case Folder.DRAFT:
              case Folder.STARRED:
                return pinButton
              case Folder.TRASH:
                return restoreButton
              default:
                return <></>
            }
          })() : importButton}
          {currentDashboard.dashboard.type === DashboardTypes.personal && canEditDashboardProperties && getPublishButton(
            dashboardWithoutCharts,
            currentWorkspace
          )}
        </FlexDiv>
      }
      default:
        return <></>
    }
  }, [canEditDashboardContent, canEditDashboardProperties, currentDashboard.dashboard, currentDashboard.ownedByUser, currentDashboard.type, currentWorkspace, getEditButton, getImportButton, getPinButton, getPublishButton, getRestoreButton])
}