/* eslint-disable max-lines */
import {ITEMS_PER_TOOLTIP_COLUMN, OPACITY, OPACITY_BLUR, seriesToNonInfiniteValues} from "components/charts/Chart.constants"
import {
  ConsolidatedSerie,
  GenericEffectiveConf,
  OriginalSerie,
  Param,
  SerieType,

} from "components/charts/line/LineChart.types"
import {extractSlicersDimensionWithDimensionObject,
  MetricWithMetricDef,
  ColorName,
  EffectiveConfSlicerTypes,
  SlicerTypes,
  Format,
  OrderBy, strToColor, MetricDef, MetricWithView, Limit, Colors,} from "@biron-data/react-bqconf"
import {HTML_DIVIDER} from "components/charts/Chart.tooltip"

import {
  drawCell,
  formatAlternativeValue,
  tooltipLineFormat,
  tooltipMetricFormat,
  tooltipSlicerFormat,
} from "components/charts/Chart.tooltipContent"
import {useCallback, useMemo, useRef} from "react"
import {standardYAxisOptions} from "components/charts/Chart.options"
import {MetricDataTree} from "classes/MetricDataTree"
import {EChartOption} from "echarts"
import Language from "language"
import {Label, sortNodeValuesASC, sortNodeValuesDESC} from "classes/MetricDataNode"
import {Axis, Hoverdata} from "components/charts/bar/BarChart"
import {formatAxisDate, formatValue} from "commons/format/formatter"
import {echartColorNa, EChartTheme, HexColor} from "components/charts/Chart.Theme"
import {isEmpty} from "@biron-data/react-components"
import {isBarColorOverridable, isLineColorOverridable} from "components/forms/chart/useChartTypes.utils"

export interface Cell {
  marker: string
  name: string
  dataIndex: number
  value: number
  highlight: boolean
  alternativeValue?: number
  format: Format
}

// Get echart label style object
export const getLabelOptionStyle = () => ({
  backgroundColor: 'white',
  padding: [2, 4, 2, 4],
  borderWidth: 0,
  borderRadius: 4,
  shadowColor: 'rgba(27, 27, 29, 0.1)',
  shadowBlur: 4,
  position: 'top',
  color: '#1B1B1D',
  fontWeight: '400',
  fontFamily: 'Poppins',
  fontSize: 10,
})

export const applySortAndLimit = (isSortBeforeLimit: boolean, applySort: () => void, applyLimit: () => void) => {
  if (isSortBeforeLimit) {
    applyLimit()
    applySort()
  } else {
    applySort()
    applyLimit()
  }
}

export const computeOrderByWithOption = (metricIndex: number, slicerIndex: number, orderBy?: OrderBy, sortSeries?: boolean): OrderBy | undefined => {
  if (sortSeries === true) {
    return {
      asc: false,
      column: metricIndex,
    }
  }

  if (sortSeries === false && !orderBy?.asc && orderBy?.column === metricIndex) {
    return {
      asc: true,
      column: slicerIndex,
    }
  }
  return orderBy
}

export function tooltipMetricHeader(conf: Pick<GenericEffectiveConf, "slicers" | "metrics">) {
  return conf.metrics.map((metric) => tooltipMetricFormat(metric.metricAlias))
    .concat(['<br/>'])
    .concat(extractSlicersDimensionWithDimensionObject(conf.slicers).map((slicer) => slicer ? tooltipSlicerFormat(slicer.dimension.alias) : ''))
    .concat([HTML_DIVIDER])
    .join('')
}

export function formatPercent(value?: number, total?: number): string {
  if (!value || !total || value === Infinity || value === -Infinity) {
    return ""
  }
  return formatAlternativeValue(`${(value / total * 100).toFixed(2)}%`)
}

const buildTableOfSeries = (yAxisLength: number, serieInfos: Pick<SerieInfo<SerieType>, "format" | "axisIndex">[], metricsLength: number, params: Param[], lineBuilder: (cells: Cell[], i: number, j: number) => string, highlightSerieIndex?: number): [string[], number | undefined] => {
  const result: string[] = []
  const isMultiMetric = metricsLength > 1

  const groupedCells: Cell[][] = params.map((param, i) => {
    return {
      highlight: i === highlightSerieIndex,
      ...param,
      axisIndex: serieInfos.length === params.length ? serieInfos[i].axisIndex : 0,
      format: serieInfos.flatMap(serie => serie.format)[isMultiMetric ? i : 0],
    }
  }).sort((a, b) => {
    if (!b.data || !a.data) {
      return 0
    }
    if (b.data[2] === 1) { // isOther is dimension 2
      return -Infinity // We want isOther serie to appear last in tooltip
    }

    if (typeof b.data[1] === "number") {
      return b.data[1] as number - (a.data[1] as number)
    }
    return b.data[0] as number - (a.data[0] as number)
  }).map((param) => ({
    name: param.seriesName ?? "",
    value: param.data?.[3],
    marker: param.marker ?? "",
    highlight: param.highlight,
    alternativeValue: param.data?.[4],
    axisIndex: param.axisIndex,
    dataIndex: param.dataIndex,
    format: param.format,
  })).reduce((previousValue, currentValue) => {
    previousValue[currentValue.axisIndex] = [...previousValue[currentValue.axisIndex], currentValue]
    return previousValue
  }, Array(yAxisLength).fill([]))
  let total = 0
  groupedCells.forEach((cells, axis) => {
    if (groupedCells.length > 1) {
      result.push(`<div style="margin-top: 5px;margin-bottom: 5px;">${Language.get('axis')} ${axis + 1}</div>`)
    }
    result.push('<table style="width: 100%;line-height: 1.1">')
    for (let i = 0; i < cells.length; i++) {
      if (i < ITEMS_PER_TOOLTIP_COLUMN) {
        result.push('<tr>')

        for (let j = i; j < cells.length; j += ITEMS_PER_TOOLTIP_COLUMN) {
          result.push(lineBuilder(cells, i, j))
        }
        result.push('</tr>')
      }
      total += cells[i].value
    }
    result.push('</table>')
  })
  return [result, isNaN(total) ? undefined : total]
}

export const tableOfContent = (yAxisLength: number, seriesMappedToAxis: Pick<SerieInfo<SerieType>, "format" | "axisIndex">[], metricsLength: number, alternativeFormats: Format[], params: Param[], highlightSerieIndex?: number, lineTotal?: number) => {
  let result: string[] = []
  const isMultiMetric = metricsLength > 1

  const [content, total] = buildTableOfSeries(yAxisLength, seriesMappedToAxis, metricsLength, params, (cells, i, j) => drawCell(
      cells[j].format,
      alternativeFormats[isMultiMetric ? j : 0], cells[j], cells.length > ITEMS_PER_TOOLTIP_COLUMN && j - i + ITEMS_PER_TOOLTIP_COLUMN < cells.length, lineTotal), // check if we are drawing multiple column and if it is not the last column
    highlightSerieIndex)

  result = content

  if (metricsLength === 1 && total) {
    result.push(HTML_DIVIDER)
    result.push(tooltipLineFormat(seriesMappedToAxis.flatMap(serie => serie.format)[0], alternativeFormats[alternativeFormats.length - 1], undefined, "Total", total))
  }
  return result.join('')
}

export interface SerieInfo<T extends SerieType> {
  axisIndex: number,
  metric: (Pick<MetricWithView, "growth"> & {
    metricDef: Pick<MetricDef, "alias">
  })
  format: Format
  series: T[]
}

export const getSerieMinMax = (series: SerieType[]) => {
  const nonInfiniteValues = seriesToNonInfiniteValues(series)
  const min = Math.min(...nonInfiniteValues)
  const max = Math.max(...nonInfiniteValues)

  return {
    min,
    max,
  }
}

export const useDefaultSerieFormatter = () => useMemo(() => (series: SerieType[]) => series, [])

export const reduceMetricToOnlyDisplayInformation = (metric: MetricWithMetricDef): (Pick<MetricWithView, "growth"> & {
  metricDef: Pick<MetricDef, "alias">
}) => ({
  growth: metric.growth ? {
    period: metric.growth.period,
    type: metric.growth.type,
  } : undefined,
  metricDef: {
    alias: metric.metricDef.alias,
  },
})

export type MetricDataTreeWithSeries<T extends SerieType> = Omit<MetricDataTree, "series"> & { series: T[] }

export interface MetricDataTreeWithOriginalSeries<T extends OriginalSerie> extends MetricDataTreeWithSeries<T> {
  type: "original"
}

export interface MetricDataTreeWithConsolidatedSeries<T extends ConsolidatedSerie> extends MetricDataTreeWithSeries<T> {
  type: "consolidated"
}

export const useSeriesMappedToAxis = <T extends SerieType>(parsedMetrics: MetricDataTreeWithSeries<T>[]): [EChartOption.YAxis[], SerieInfo<T>[]] => useMemo(() => {
  const serieInfos: SerieInfo<T>[] = parsedMetrics.map((parsedMetric, i) => ({
    axisIndex: i === 0 || !parsedMetric.metric.extraConf?.isDisplayedOnSecondaryAxis ? 0 : 1,
    metric: reduceMetricToOnlyDisplayInformation(parsedMetric.metric),
    format: parsedMetric.format,
    series: parsedMetric.series,
  }))
  const serieMinMaxFirstAxis = getSerieMinMax(serieInfos.filter(info => info.axisIndex === 0).flatMap(info => info.series))
  const serieMinMaxSecondAxis = getSerieMinMax(serieInfos.filter(info => info.axisIndex === 1).flatMap(info => info.series))

  const yAxis: EChartOption.YAxis[] = [
    {
      triggerEvent: true,
      ...standardYAxisOptions(),
      min: serieMinMaxFirstAxis.min < 0 ? serieMinMaxFirstAxis.min : 0,
      max: serieMinMaxFirstAxis.max,
    },
    ...(serieInfos.filter(serieInfo => serieInfo.axisIndex !== 0).length > 0 ? [{
      triggerEvent: true,
      ...standardYAxisOptions(),
      min: serieMinMaxSecondAxis.min < 0 ? serieMinMaxSecondAxis.min : 0,
      max: serieMinMaxSecondAxis.max,
    }] : []),
  ]

  return [yAxis, serieInfos]
}, [parsedMetrics])


export const OVERLAY_LINE_STYLE: EChartOption.LineStyle = {
  color: "#1B1B1D",
}

export const OVERLAY_LABEL_STYLE: {
  color: string | undefined
  fontWeight: 'normal' | 'bold' | 'bolder' | 'lighter' | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | undefined
} = {
  color: "#1B1B1D",
  fontWeight: 600,
}

export const OVERLAY_PROPERTIES: EChartOption.YAxis = {
  axisLine: {
    show: true,
    lineStyle: OVERLAY_LINE_STYLE,
  },
  axisLabel: OVERLAY_LABEL_STYLE,
}

export const overlaidAxis = (data: Hoverdata, serieInfos: (Pick<SerieInfo<SerieType>, "series" | "axisIndex">)[], axis: EChartOption.YAxis[]): EChartOption.YAxis[] => {
  const axisOverlayTriggeredBySeries = axis.map((a, i) => {
    if (serieInfos.filter(info => info.axisIndex === i).find((info) => info.series.find(serie => serie.label === data?.seriesName))) {
      return {
        ...a,
        ...OVERLAY_PROPERTIES,
      }
    } else {
      return a
    }
  })
  return axisOverlayTriggeredBySeries.map((a, i) => data.yAxisIndex === i ? ({
    ...a,
    ...OVERLAY_PROPERTIES,
  }) : a)
}

export const overlaidLineSeries = (renderedSeries: EChartOption.SeriesLine[], yAxisIndex?: number) => yAxisIndex === undefined ? renderedSeries : renderedSeries.map(serie => ({
  ...serie,
  lineStyle: {
    ...serie.lineStyle,
    opacity: serie.yAxisIndex === yAxisIndex ? OPACITY : OPACITY_BLUR,
  },
  itemStyle: {
    ...serie.itemStyle,
    opacity: serie.yAxisIndex === yAxisIndex ? OPACITY : OPACITY_BLUR,
  },
}))

export const overlaidBarSeries = (renderedSeries: (EChartOption.SeriesBar | EChartOption.SeriesLine)[], yAxisIndex?: number) => yAxisIndex === undefined ? renderedSeries : renderedSeries.map(serie => ({
  ...serie,
  itemStyle: {
    opacity: serie.yAxisIndex === yAxisIndex ? OPACITY : OPACITY_BLUR,
  },
}))

export const useXAxis = (hasSlicers: boolean, parsedData: MetricDataTree[]) => useMemo(() => {
  if (hasSlicers) {
    return parsedData[0].getXAxisAt(0)
  } else {
    return undefined
  }
}, [hasSlicers, parsedData])


export const useLegend = (serieInfos: Pick<SerieInfo<SerieType>, 'series'>[]) => useMemo(() => {
  return serieInfos.flatMap(s => s.series).map(serie => serie.label).sort((a, b) => {
    if (a === Language.get("chart-others-series")) {
      return 1
    } else if (b === Language.get("chart-others-series")) {
      return -1
    }
    if (typeof a === "string" && typeof b === "string") {
      return a.localeCompare(b)
    }
    return a > b ? 1 : -1
  })
}, [serieInfos])

export const useAxisFormat = (yAxis: Axis[], serieInfos: Pick<SerieInfo<SerieType>, 'axisIndex' | 'format'>[]) => useMemo(() => {
  return yAxis.map((axis, i) => serieInfos.find(serieInfo => serieInfo.axisIndex === i)?.format).filter(format => format !== undefined) as Format[]
}, [serieInfos, yAxis])

export const useSumOfTreeSeries = (trees: Pick<MetricDataTree, "getSeries" | "getXAxisAt">[], indexOfSortedMetrics: number[], valueProcessor?: (value1: number, value2: number) => number) => useMemo(() => {
  if (trees.length <= 1 || indexOfSortedMetrics.length === 0) {
    return undefined
  }

  const numberOfValues = trees[0].getSeries()[0].values.length
  const axisValues = trees[0].getXAxisAt(0)

  if (numberOfValues !== axisValues.length) {
    throw Error('Unable to sort as source values contains blanks')
  }

  const consolidateValueProcessor = valueProcessor ?? ((value1: number, value2: number) => value1 + value2)

  const sumSerieValue = (slicerValueIndex: number, series: OriginalSerie[]) => {
    const numberOfSeries = series.length

    return Array(numberOfSeries).fill(0).reduce((seriesAcc, __, currentSerieIndex) => {
      return seriesAcc + (series[currentSerieIndex].values[slicerValueIndex] ?? 0)
    }, 0)
  }

  return Object.fromEntries(
    new Array(numberOfValues).fill(0).map((_, valueIndex) => [axisValues[valueIndex], indexOfSortedMetrics.reduce((sumOfSortedMetricValues, indexOfSortedMetric) => {
      return consolidateValueProcessor(sumOfSortedMetricValues, sumSerieValue(valueIndex, trees[indexOfSortedMetric].getSeries()))
    }, 1)]))
}, [indexOfSortedMetrics, trees, valueProcessor])

export const useFillBlanks = (parsedData: MetricDataTree[], isFirstSlicerOfTypeDate: boolean, hasSlicer: boolean, expectedDates?: string[]) => useMemo(() => parsedData.map(data => {
  let result: MetricDataTree = data
  if (isFirstSlicerOfTypeDate) {
    result = result.fillBlanks(expectedDates ?? [])
  } else if (hasSlicer) {
    result = result.fillBlanks(parsedData[0].getXAxisAt(0))
  }
  return result
}), [expectedDates, hasSlicer, isFirstSlicerOfTypeDate, parsedData])

export const useLineChartRawParsedMetrics = (
  parsedData: MetricDataTree[],
  metricsLength: number,
  slicersLength: number,
  firstLimit: Limit,
  secondLimit: Limit,
  firstSlicerIndex: number,
  secondSlicerIndex: number,
  orderBy?: OrderBy,
  sumOfValues?: { [key: string]: number },
) => useMemo(() => {
  const rawParsedMetrics = parsedData.map(data => {
    let result: MetricDataTree = data

    result = result.sortByLabelsAt(0) // First alphabetical sort on the axis (performed by lego)

    if (sumOfValues) {
      result = result.sortByMaxValues(sumOfValues, Boolean(orderBy?.asc))
    } else {
      if (orderBy && orderBy.column === slicersLength) {
        result = result.sortByValues(!orderBy?.asc)
      } else {
        if (secondSlicerIndex === -1) {
          result = result.sortBySlicerValuesAt(firstSlicerIndex, orderBy?.asc)
        } else {
          result = result.sortBySlicerValuesAt(secondSlicerIndex, orderBy?.asc)
        }
      }
    }

    if (firstLimit && firstLimit.limitSeries) {
      result = result.limitAt(false, firstSlicerIndex, firstLimit.limitSeries, firstLimit.hideOthers ?? true) as MetricDataTree
    }
    if (secondLimit && secondLimit.limitSeries && secondSlicerIndex !== -1) {
      result = result.limitAt(false, secondSlicerIndex, secondLimit.limitSeries || 100, secondLimit.hideOthers) as MetricDataTree
    }
    if (orderBy?.column === firstSlicerIndex && orderBy?.asc) {
      result = result.sortByLabelsAt(firstSlicerIndex)
      if (secondSlicerIndex !== -1) {
        result = result.sortByLabelsAt(secondSlicerIndex)
      }
    }
    return result
  })

  return metricsLength > 1 && slicersLength === 0 ? rawParsedMetrics.sort(orderBy?.asc ? sortNodeValuesDESC : sortNodeValuesASC) : rawParsedMetrics
}, [parsedData, metricsLength, slicersLength, orderBy, sumOfValues, firstLimit, secondLimit, secondSlicerIndex, firstSlicerIndex])

export const useBarChartRawParsedMetrics = (
  parsedData: MetricDataTree[],
  slicers: SlicerTypes[],
  isFirstSlicerOfTypeDate: boolean,
  hasSlicer: boolean,
  firstLimit: Limit,
  secondLimit: Limit,
  firstSlicerIndex: number,
  secondSlicerIndex: number,
  metricIndex: number,
  orderBy?: OrderBy,
  sumOfValues?: { [key: string]: number },
  expectedDates?: string[]) => useMemo(() => parsedData.map((data) => {
  let result: MetricDataTree = data

  if (isFirstSlicerOfTypeDate) {
    result = result.fillBlanks(expectedDates ?? [])
  } else if (hasSlicer) {
    result = result.fillBlanks(parsedData[0].getXAxisAt(0))
  }

  applySortAndLimit(orderBy?.column === 0 && slicers.length > 0, () => {
      if (orderBy?.column === metricIndex) {
        if (sumOfValues) {
          result = result.sortByMaxValues(sumOfValues, Boolean(orderBy.asc))
        } else {
          result = result.sortByValues(Boolean(!orderBy.asc))
        }
      }
      if (orderBy && orderBy.column === firstSlicerIndex && orderBy.asc) {
        result = result.sortByLabelsAt(firstSlicerIndex)
        if (secondSlicerIndex !== -1) {
          result = result.sortByLabelsAt(secondSlicerIndex)
        }
      }
    },
    () => {
      if (firstLimit && firstLimit.limitSeries) {
        result = result.limitAt(slicers.length > 1, firstSlicerIndex, firstLimit.limitSeries, firstLimit.hideOthers) as MetricDataTree
      }
      if (secondLimit && secondLimit.limitSeries) {
        result = result.limitAt(false, 1, secondLimit.limitSeries, secondLimit.hideOthers) as MetricDataTree
      }
    })

  return result
}), [parsedData, isFirstSlicerOfTypeDate, hasSlicer, orderBy, slicers.length, expectedDates, metricIndex, firstSlicerIndex, sumOfValues, secondSlicerIndex, firstLimit, secondLimit])

export const useScatterChartRawParsedMetrics = (
  parsedData: MetricDataTree[],
  slicers: Pick<SlicerTypes, "id">[],
  firstLimit: Limit | undefined,
  secondLimit: Limit | undefined,
  firstSlicerIndex: number,
  sumOfValues?: { [key: string]: number }) => useMemo(() => parsedData.map((data) => {
  let result: MetricDataTree = data

  if (sumOfValues) {
    result = result.sortByMaxValues(sumOfValues, false)
  }

  if (firstLimit && firstLimit.limitSeries) {
    result = result.limitAt(slicers.length > 1, firstSlicerIndex, firstLimit.limitSeries, firstLimit.hideOthers) as MetricDataTree
  }
  if (secondLimit && secondLimit.limitSeries) {
    result = result.limitAt(false, 1, secondLimit.limitSeries, secondLimit.hideOthers) as MetricDataTree
  }

  return result
}), [parsedData, slicers.length, sumOfValues, firstLimit, secondLimit, firstSlicerIndex])

export const useColors = (index = 0, data: MetricDataTree, values: Label[]): { [p: string]: HexColor } => {
  const ref = useRef<{ [x: string]: HexColor }>({})
  const previousColors = useRef<{ [key: string]: HexColor }>({})

  const colors = useMemo(() => {
    const colorScale = data.getColorScaleAt(index)
    const newColors = {
      ...values.reduce((acc: Record<string, HexColor>, value, idx) => ({
        ...acc,
        [value]: colorScale[idx],
      }), {}),
      ...previousColors.current,
    }
    previousColors.current = newColors
    return newColors
  }, [data, index, values])

  return useMemo(() => {
    const newRef = Object.fromEntries(values.map(value => [value, ref.current[value] ?? colors[value]]))
    ref.current = newRef
    return newRef
  }, [colors, values])
}

export const useGetSeriesLabel = (dataIndex: number) => useCallback((displayedFormat: Format | undefined, alternativeFormat: Format | undefined, displayLabels?: boolean) => ({
  show: displayLabels,
  formatter: (serieInfo: { data: ("string" | number)[] }) => {
    return displayedFormat ? formatValue(serieInfo?.data?.[dataIndex], {
      ...(alternativeFormat ?? displayedFormat),
      summarizeValue: true,
    }) : serieInfo?.data?.[dataIndex]
  },
  ...getLabelOptionStyle(),
}), [dataIndex])

export const useAxisLabel = (xAxis: string[] | undefined, firstLimit: Limit, slicers: Pick<EffectiveConfSlicerTypes, "type">[], height: number) => {
  const charactersOverflow = useMemo(() => height / 20, [height])

  return useMemo(() => ({
    showMinLabel: true,
    hideOverlap: true,
    formatter(value: string, i: number) {
      if (!xAxis) {
        return undefined
      }

      const isLastOfAxis = i === xAxis.length - 1
      const isLimitApplied = ((firstLimit?.limitSeries || 100) - 1) < i
      const isOtherIndicator = firstLimit?.hideOthers === false && (isLimitApplied && isLastOfAxis)

      if (slicers.length > 0 && slicers[0].type === "date" && !isOtherIndicator) {
        return formatAxisDate(value)
      } else {
        // We manually compute overflow because EChart does not compute it correctly with the combo: rotate + fixed width + containLabel: false
        return value.length <= charactersOverflow ? value : `${value.substr(0, charactersOverflow)}...`
      }
    },
    rotate: 45,
  }), [charactersOverflow, firstLimit?.hideOthers, firstLimit?.limitSeries, slicers, xAxis])
}

export const useBarChartColors = (slicerCount: number, metricCount: number, initialColors: {
  [p: string]: HexColor
}, seriesLabel: string[], metricColors: (ColorName | undefined)[]): HexColor[] => useMemo(() => {
  if (isBarColorOverridable(slicerCount, metricCount)) {
    return metricColors.map((color, i) => color ? strToColor.get(color as ColorName) ?? EChartTheme.color[i] : EChartTheme.color[i])
  }
  return seriesLabel.map((t) => Object.entries(initialColors).find(([value]) => value === t)?.[1]).map((color) => color ? color : undefined).filter(color => !isEmpty(color)) as HexColor[]
}, [initialColors, metricColors, metricCount, seriesLabel, slicerCount])

export const useLineChartColors = (slicerCount: number, initialColors: {
  [p: string]: string
}, seriesLabel: string[], metricColors: (ColorName | undefined)[]): Colors[] | HexColor[] => useMemo(() => {
  if (isLineColorOverridable(slicerCount)) {
    return metricColors.map((color, i) => color ? strToColor.get(color as ColorName) ?? EChartTheme.color[i] : EChartTheme.color[i])
  }
  return seriesLabel.map((t) => Object.entries(initialColors).find(([value]) => value === t)?.[1] ?? echartColorNa).map((color) => color ? color : undefined) as HexColor[]
}, [initialColors, metricColors, seriesLabel, slicerCount])