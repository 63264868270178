import React, {forwardRef} from 'react'
import Language from 'language'
import {Alert} from 'antd'
import WCBody from "components/widgetContainer/WidgetContainer.Body"
import {ShapeDimension} from "types/charts"
import {ChartSelection, EmptyObject, WorkflowResultTypes} from "classes/workflows/query-workflows/QueryWorkflow"
import {ChartDetailWithoutLayout} from "components/widgetContainer/WidgetContainer"
import {WidgetTypes} from "commons/dashboard/dashboard.types"

interface Props {
  chart: ChartDetailWithoutLayout
  data?: WorkflowResultTypes | EmptyObject
  selection: ChartSelection
  dimensions: ShapeDimension
  onSelectionChange: (newSelection: ChartSelection) => any
  withSummary: boolean
}

export default forwardRef<any, Props>(function WCInnerLoader({
                                                               chart,
                                                               data,
                                                               selection,
                                                               dimensions,
                                                               onSelectionChange,
                                                               withSummary,
                                                             }, ref) {
  if (data && Object.entries(data).length) {
    const {message} = (data as WorkflowResultTypes)
    if (message) {
      const extra = typeof message.extra === 'string' ? message.extra : JSON.stringify(message.extra)
      return <Alert
        type={message.type}
        message={<span title={extra}>{Language.get(message.message)}</span>}
        banner
      />
    } else {
      return <WCBody ref={ref} {...{
        chart,
        data: data as WorkflowResultTypes,
        dimensions,
        selection,
        onSelectionChange,
        withSummary,
      }} />
    }
  } else if (chart.type === WidgetTypes.DIVIDER) {
    return <WCBody ref={ref} {...{
      chart,
      data: data as WorkflowResultTypes,
      dimensions,
      selection,
      onSelectionChange,
      withSummary,
    }} />
  } else {
    return null
  }
})
