import React, {FunctionComponent, useMemo} from 'react'
import {DetailsUsages} from "components/dataSourceDoc/details/Details.Usages"
import {ChartSource, DataSourceObjectType, Link} from 'components/dataSourceDoc/DataSource.types'
import {Column, MatchParams} from "components/dataSourceDoc/DataSource"
import DataSourceLayout, {ContentContainer, InformationsContainer} from "components/dataSourceDoc/DataSource.Layout"
import {CardEffectDiv} from "themes/SharedStyledComponent"
import {useNavigate, useParams} from "react-router-dom"
import DetailsInformations from "components/dataSourceDoc/details/Details.Informations"
import {MetaModelView} from "@biron-data/react-bqconf"
import {Export} from "components/forms/Form.types"
import Language from "language"
import {ExpandedWorkspace} from "redux/models/workspace"
import {useMetricsUsages} from "components/dataSourceDoc/DataSource.hooks"
import {CardContentContainerWithLimitedHeight, DataSourceDocTitle} from "components/dataSourceDoc/DataSource.style"

interface Props {
  data: Column[]
  views: MetaModelView[]
  currentWorkspace: ExpandedWorkspace
  currentExports: Export[]
}

export const DetailsMetric: FunctionComponent<Props> = ({
                                                          data,
                                                          views,
                                                          currentWorkspace,
                                                          currentExports,
                                                        }) => {
  const navigate = useNavigate()
  const {code} = useParams<MatchParams>()
  const metric = useMemo(() => {
    const matchingMetric = data.find(m => m.code === code && m.type === DataSourceObjectType.METRIC)
    if (matchingMetric) {
      return matchingMetric
    } else {
      navigate("/")
      return data.find(m => m.type === DataSourceObjectType.METRIC)!
    }
  }, [code, data, navigate])

  const usages = useMetricsUsages(
    currentExports,
    currentWorkspace,
    code ?? "",
  )

  const links: Link[] = useMemo(() => views.filter(v => v.metrics.find(d => d.code === code)).map(v => ({
    code: v.code,
    alias: v.alias,
  })), [code, views])

  return <DataSourceLayout code={code} alias={metric.alias} type={DataSourceObjectType.METRIC}>
    <InformationsContainer>
      <DetailsInformations
        description={metric.description}
        links={links}
        id={metric.code}
        suffix={metric.metric?.formatSuffix}
        decimales={metric.metric?.formatPrecision}
        asRatio={metric.metric?.asRatio}/>
    </InformationsContainer>
    <ContentContainer>
      <CardEffectDiv>
        <CardContentContainerWithLimitedHeight>
          <DataSourceDocTitle>{Language.get("dataDoc.details.usages")}</DataSourceDocTitle>
          <DetailsUsages usages={usages} sources={[ChartSource.METRIC]}/>
        </CardContentContainerWithLimitedHeight>
      </CardEffectDiv>
    </ContentContainer>
  </DataSourceLayout>
}